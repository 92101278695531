import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Products from '../components/indexComponents/products'
import Customers from '../components/indexComponents/customers'
import PhilosophyAndMission from '../components/indexComponents/philosophyAndMission'
import Founders from '../components/indexComponents/founders'
import Contact from '../components/indexComponents/contact'
import Introduction from '../components/indexComponents/introduction'


const IndexPage = () => {
    return (
        <div>
            <Layout>
                <Seo title="Company" description="BricoBrowser associated with Ricta Technologies" />
                <Introduction/>
                <Products/>
                <Customers/>
                <PhilosophyAndMission/>
                <Founders/>
                <Contact/>
            </Layout>
        </div>
    )

}
export default IndexPage