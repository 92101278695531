import React from 'react'
import Riccardo from '../../images/Riccardo.jpg'
import Silvia from '../../images/Silvia.jpg'
import Zoom from 'react-reveal/Zoom'

const Founders = () => {
    return (
        <section className="bg-white py-4">
            <div className="text-xl md:text-2xl lg:text-3xl text-white bg-mainColor p-1 my-4  text-center">Meet the Founders </div>
            <h3 className="sm:text-xl text-lg text-mainColor w-2/3 m-auto my-4 text-center">Behind our software’s success is the talent and passion of a diverse group of specialists.</h3>
            <hr className="horizontalLine"/>
            <div className="grid grid-cols-1 lg:grid-cols-2 max-w-screen-xl w-4/5 md:w-full m-auto ">  
                <Zoom>
                    <div className="flex justify-center items-center my-4">
                        <div className="max-w-lg md:max-h-64 bg-mainColor border-2 border-gray-300 p-6 rounded-3xl tracking-wide shadow-lg">
                            <div id="header" className="flex items-center mb-4"> 
                                <img alt="Riccardo T." className="w-20 border-2 border-gray-300" src={Riccardo} />
                                <div id="header-text" className="leading-5 ml-6 sm">
                                    <h4 id="name" className="text-xl text-white font-semibold">Riccardo T.</h4>
                                    <h5 id="job" className="font-semibold text-white">Co-Founder & CEO</h5>
                                </div>
                            </div>
                            <div className="text-left">
                                <p className="tracking-wide text-white">Riccardo is a seasoned and passionate Security Expert, he possesses over 20 years of combined experience in Cyber-Physical Security Operations and software development with focus on Digital Forensics, Incident Response and OSINT.</p>
                            </div>
                        </div>
                    </div>
                </Zoom>
                <Zoom>
                    <div className="flex justify-center items-center my-4">
                        <div className="max-w-lg md:max-h-64  bg-mainColor border-2 border-gray-300 p-6 rounded-3xl tracking-wide shadow-lg">
                            <div id="header" className="flex items-center mb-4"> 
                                <img alt="Silvia L." className="w-20 border-2 border-gray-300" src={Silvia} />
                                <div id="header-text" className="leading-5 ml-6 sm">
                                    <h4 id="name" className="text-xl text-white font-semibold">Silvia L.</h4>
                                    <h5 id="job" className="font-semibold text-white">Co-Founder & COO</h5>
                                </div>
                            </div>
                            <div className="text-left">
                                <p className="tracking-wide text-white">Silvia is a Senior Financial Expert as she has over 15 years of combined experience in Business Administration, Risk Management, Fraud Prevention with focus on Digital Innovation and Business Strategy.
                                </p>
                            </div>
                        </div>
                    </div>
                </Zoom>
            </div>
        </section>
    )
}
export default Founders